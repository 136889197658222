<template>
  <div class="booking-section">
    <div
      v-if="event?.booking_id"
      class="booking-data"
    >
      <div class="booking-detail">
        <div class="booking-head">
          <span>Booking Details </span>
          <v-icon @click="clearData">
            mdi-close
          </v-icon>
        </div>
        <div class="booking-sub-head">
          <span>ID: {{ event.booking_id }} </span>
          <span>
            <status-badge
              :status="event?.booking_status"
              :text="event?.booking_status"
            />
          </span>
        </div>
        <div class="customer-detail">
          <v-avatar
            color="grey lighten-2"
            size="50"
          />
          <span>
            <div class="name">{{ event.customer_name }}</div>
            <div>{{ event.customer_email }}</div>
          </span>
        </div>

        <v-divider />
        <div class="station-info">
          <div class="head">
            <v-icon>mdi-map-marker</v-icon>
            <span>Station Information </span>
          </div>
          <div class="value">
            <label>Station Name:</label>
            <span>{{ event.customer_email }}</span>
          </div>
          <div class="value">
            <label>Station ID:</label>
            <span>{{ event.charge_station_charge_point_id }}</span>
          </div>
          <div class="value">
            <label>Address:</label>
            <span>{{ event.location_name }}</span>
          </div>
          <div class="value">
            <label>Charge Point:</label>
            <span>{{ event.charger_connector_id }}</span>
          </div>
          <div class="value">
            <label>Connector:</label>
            <span>{{ event.connector_format }}</span>
          </div>
        </div>

        <v-divider />
        <div class="booking-time">
          <div class="head">
            <v-icon>mdi-calender-outlined</v-icon>
            <span>Booking Time </span>
          </div>
          <div class="value">
            <label>Start Time:</label>
            <span>{{ event.start_time_format }}</span>
          </div>
          <div class="value">
            <label>Start Date:</label>
            <span>{{ event.booking_date }}</span>
          </div>
          <div class="value">
            <label>End Time:</label>
            <span>{{ event?.end_time_format }}</span>
          </div>
          <div class="value">
            <label>Durtaion:</label>
            <span>{{ event?.duration || "-" }}</span>
          </div>
        </div>

        <v-divider />
        <div class="billing-info">
          <div class="head">
            <v-icon>mdi-calender-outlined</v-icon>
            <span>Billing Information </span>
          </div>
          <div class="value">
            <label>Energy Used:</label>
            <span>{{ event?.energy_used || "-" }}</span>
          </div>

          <div class="value">
            <label>Revenue:</label>
            <span>{{ event?.cost || "-" }}</span>
          </div>
        </div>
        <div
          v-if="
            !['rejected', 'cancelled', 'completed'].includes(
              event.booking_status
            )
          "
          class="action-btn"
        >
          <v-btn @click="editEvent">
            Edit
          </v-btn>
          <v-btn
            color="red darken-1"
            @click="cancelBooking"
          >
            Cancel Booking
          </v-btn>
        </div>
      </div>
    </div>
    <div
      v-else
      class="no-data"
    >
      <v-icon>mdi-calendar-blank</v-icon>
      <div class="no-data-title">
        No Booking Selected
      </div>
      <div class="no-data-note">
        Select a booking from the list or calendar to view details
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {
    StatusBadge: () => import("@/components/base/Status-Badge.vue"),
  },
  props: {
    event: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  methods: {
    clearData() {
      this.$emit("clearData");
    },
    editEvent() {
      const params = {
        ...this.event,
        id: this.event.booking_id,
      };
      this.$emit("editEvent", params);
    },
    cancelBooking() {
      const params = {
        ...this.event,
      };
      params.id = params.booking_id;
      params.booking_status = "cancelled";
      this.$store.dispatch("csmsBookings/updateBooking", params).then((res) => {
        this.$store.dispatch("alerts/success", res?.message);
      });
    },
  },
};
</script>
<style lang="sass" scoped>
.booking-section
    padding: 1rem
    height: 100%
    .no-data
        height: inherit
        display: flex
        justify-content: center
        align-items: center
        flex-direction: column
        font-size: 18px
        padding: 0 3rem
        i
            font-size: 6rem
            color: #e1e1e1
        .no-data-title
            font-weight: 600
            font-size: 20px
        .no-data-note
            text-align: center
            font-size: 14px
            color: lightgray


.booking-detail
    .booking-head
        display: flex
        justify-content: space-between
        span
            font-size: 18px
            font-weight: 600
        i
            cursor: pointer
    .booking-sub-head
        margin-top: 5px
        display: flex
        justify-content: space-between

    .customer-detail
        background: #f7f7f7
        border-radius: 10px
        padding: 1rem
        margin-top: 5px
        display: flex
        align-items: center
        gap: 1rem
        .name
            font-weight: 600

    .v-divider
        margin: 1.5rem 0 !important

    .station-info, .booking-time, .billing-info
        .head
            display: flex
            align-items: center
            padding-bottom:0.5rem
            span
                font-weight: 600
                font-size: 16px
        .value
            display: flex
            justify-content: space-between
            padding-top: 5px
            padding: 0 1.5rem
            span
                font-weight: 600

    .action-btn
        margin-top: 2rem
        display: flex
        gap: 1rem
        justify-content: center
        .v-btn
            flex: 1
            margin: 0 !important
</style>
